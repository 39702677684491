/* Full screen loading */
.overlayWrapper {
  height: 130vh;
}

.uploadBox {
  margin: 20px;
  border: 1px solid #1C6EA4;
  display: inline-block;
  border-radius: 20px;
  padding: 30px;
  background-color: #e0ecff;
  box-shadow: inset 0 0 0.1em #fff, 0.2em 0.2em 0.2em rgba( 0, 0, 0, 0.3 );
}

.customFileUpload {
  display: inline-block;
  line-height: 2.2em;
  padding: 0 0.62em;
  border: 1px solid #666;
  border-radius: 0.25em;
  background-image: linear-gradient( to bottom, #fff, #ccc );
  box-shadow: inset 0 0 0.1em #fff, 0.2em 0.2em 0.2em rgba( 0, 0, 0, 0.3 );
  font-family: arial, sans-serif;
  font-size: 0.8em;
}

.customFileUpload:hover {
  background-image: linear-gradient( to bottom, rgb(226, 223, 223), #ccc );
}

.customFileUpload:active {
  background-image: linear-gradient( to bottom, #fff, #ccc );
}
