.header {
  max-width: 100%;
  background-color: #044599;
  padding: 0;
  margin: 0;
  display: flex;
  height: 3rem;
  justify-content: space-between;
  align-content: center;
  box-shadow: inset 0 0 0.1em #fff, 0.2em 0.2em 0.2em rgba( 0, 0, 0, 0.3 );
}

.header nav {
  align-self: center;
  height: 100%;
  max-width: 100%;
}

.header ul {
  height: 100%;
  max-width: 100%;
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
  align-items: center;
  justify-content: center;
}

.header li {
  margin: 0 1rem;
  width: 100px;
}

.header a {
  color: white;
  text-decoration: none;
}

.header a:hover,
.header a:active,
.header a.active {
  color: #95bcf0;
}

.header inactive {
  display: none;
  margin-top: 30px;
}


/* Dropdown Menu */

.dropdown {
  position: absolute;
  top: 58px;
  transform: translateX(-35%);
  padding: 1rem;
  overflow: hidden;
  background-color: #044599;
  border-radius: 20px;
  text-align: center;
}

/* .menu-item {
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: var(--border-radius);
  padding: 0.5rem;
}

.menu-item .icon-button {
  margin-right: 0.5rem;
}


.menu-item .icon-button:hover {
  filter: none;
}

.menu-item:hover {
  background-color: #525357;
} */
