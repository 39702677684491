@media (min-width: 900px) {
  .mainContainer { grid-template-columns: repeat(3, 1fr); }
}

/* Main Container  style */
.mainContainer {
  max-width: 100%;
  display: grid;
  margin: 0 auto;
  gap: 1rem;
  text-align: center;
}

/* Container Items style */
.hiddenContainerItems {
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
  display: grid;
  border-radius: 20px;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: center;
  height: 300px;
  align-items: baseline;
}

/* Container Items style */
.containerItems {
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
  border: 1px solid #1C6EA4;
  border-radius: 20px;
  padding: 20px;
  justify-content: center;
  display: inline-block;
  vertical-align: top;
  background-color: #e0ecff;
  box-shadow: inset 0 0 0.1em #fff, 0.2em 0.2em 0.2em rgba( 0, 0, 0, 0.3 );
}

/* Search results style */
.tablestyle {
  margin-top: -10px;
  list-style: none;
  padding: 1px 1px;
  display: inline-block;
  vertical-align: top;
}
.tablestyle img {
  vertical-align: middle;
  border-radius: 10px;
}
.tablestyle li {
  list-style:none;
  padding: 5px 15px;
  border: 1px solid #1C6EA4;
  margin-top: 10px;
  text-align: center;
  box-shadow: 2px 2px #888888;
  border-radius: 20px;
}
.tablestyle li:hover{
  color: white;
  background-color: rgb(60, 129, 226);
}
.tablestyle li:active{
  color: white;
  background-color: #044599;
}
.tablestyle a {
  color: purple;
  text-decoration: none;
}
.tablestyle a:link {
  color: black;
}
.tablestyle a:hover {
  color: blue;
}

/* button style */
.buttonDate {
  list-style:none;
  padding: 5px 45px;
  border: 1px solid #1C6EA4;
  margin-top: 10px;
  text-align: center;
  box-shadow: 2px 2px #888888;
  border-radius: 20px;
}
.buttonDate:hover {
  background-image: linear-gradient( to bottom, rgb(226, 223, 223), #ccc );
}
.buttonDate:active {
  background-image: linear-gradient( to bottom, #fff, #ccc );
}
