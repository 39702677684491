/* Full screen loading */
.overlayWrapper {
  height: 100vh;
}

.viewBox {
  margin: 20px;
  border: 1px solid #1C6EA4;
  display: inline-block;
  border-radius: 20px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #e0ecff;
  box-shadow: inset 0 0 0.1em #fff, 0.2em 0.2em 0.2em rgba( 0, 0, 0, 0.3 );
  text-align: left;
  list-style: none;
}

.tablestyle {
  list-style:none;
  padding: 1px 1px;
}

.tablestyle img {
  vertical-align: middle;
  border-radius: 10px;
}

.tablestyle li {
  list-style:none;
  padding: 5px 15px;
  border: 1px solid #1C6EA4;
  margin-top: 10px;
  text-align: center;
  box-shadow: 2px 2px #888888;
  border-radius: 20px;
  display: inline-block;
}
